import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const TwoUpCard = ({input}) => (
  <div className="pl5-l pr5-l mt4 mb4-l flex-l flex-wrap justify-between">
    <div className="w-100 w-50-l pa4">
      <Img fluid={input.primary.card_one_image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
      <div className="f3 blue medium lh-copy mt4 mb3">{input.primary.card_one_title.text}</div>
      <div className="lh-copy f4">{input.primary.card_one_text.text}</div>
    </div>
    <div className="w-100 w-50-l pa4">
      <Img fluid={input.primary.card_two_image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
      <div className="f3 blue medium lh-copy mt4 mb3">{input.primary.card_two_title.text}</div>
      <div className="lh-copy f4">{input.primary.card_two_text.text}</div>
    </div>
  </div>
);
export default TwoUpCard;

TwoUpCard.propTypes = {
  input: PropTypes.object.isRequired
};
