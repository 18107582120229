import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ImageText = ({input}) => (
  <div className={`pl3 pr3 pl5-l pr5-l flex-l items-center bottom-border-${input.primary.border_bottom} top-border-${input.primary.border_top} relative text-${input.primary.text_position} `}>
    <Img fluid={input.primary.image.localFile.childImageSharp.fluid} className="w-100 h-auto aspect-ratio overflow-hidden relative w-50-l" />
    <div className="w-100 w-50-l pr6-l pl6-l mt4 mt0-l mb5-l">
      <div dangerouslySetInnerHTML={{__html: input.primary.text.html}} className="f4 lh-copy" />
    </div>
  </div>
);
export default ImageText;

ImageText.propTypes = {
  input: PropTypes.object.isRequired
};
