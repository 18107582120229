import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HeaderSpacer from '../slices/HeaderSpacer';
import ContactBranch from '../slices/ContactBranch';
import CenteredTextBlock from '../slices/CenteredTextBlock';
import ImageText from '../slices/ImageText';
import PageBanner from '../components/PageBanner';
import TwoColText from '../components/TwoColText';
import SliceQuote from '../slices/SliceQuote';
import Spacer from '../slices/Spacer';
import CenteredTitleBlock from '../slices/CenteredTitleBlock';
import ServiceSliderSlice from '../slices/ServiceSliderSlice';
import BasicGallery from '../slices/BasicGallery';
import TwoUpCard from '../slices/TwoUpCard';
import Faqs from '../slices/Faqs';

export default class SliceZone extends Component {
  render() {
    const {allSlices} = this.props;
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'header_spacer':
          return <HeaderSpacer key={s.id} input={s} />;
        case 'branch':
          return <ContactBranch key={s.id} input={s} />;
        case 'banner_image':
          return <PageBanner key={s.id} bannerImage={s.primary.image} />;
        case 'centered_text_block':
          return <CenteredTextBlock key={s.id} input={s} />;
        case 'image___text':
          return <ImageText key={s.id} input={s} />;
        case 'quote':
          return <SliceQuote key={s.id} input={s} />;
        case 'spacer':
          return <Spacer key={s.id} />;
        case 'centered_title_block':
          return <CenteredTitleBlock key={s.id} input={s} />;
        case 'services_slider':
          return <ServiceSliderSlice key={s.id} />;
        case 'two_column_text':
          return <TwoColText key={s.id} text={s.primary.text.html} />;
        case 'image_carousel':
          return <BasicGallery key={s.id} input={s} />;
        case 'two_up_card':
          return <TwoUpCard key={s.id} input={s} />;
        case 'faqs':
          return <Faqs key={s.id} input={s} />;
        default:
          return null;
      }
    });
    return <div className="slice-zone">{slice}</div>;
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired
};
