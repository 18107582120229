import React from 'react';
import PropTypes from 'prop-types';

const CenteredTitleBlock = ({input}) => (
  <div className="pt5-l pb5-l pt3 pb3 pl4 pr4 relative mt4-l mt0">
    <h2 className="blue tc f2 f1-l">{input.primary.title.text}</h2>
  </div>
);
export default CenteredTitleBlock;

CenteredTitleBlock.propTypes = {
  input: PropTypes.object.isRequired
};
