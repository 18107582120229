import React from 'react';
import PropTypes from 'prop-types';

const SliceQuote = ({input}) => (
  <div className="pt6 pb6 pl4 pr4 tc relative bg-blue mt5 mt0-l">
    <div dangerouslySetInnerHTML={{__html: input.primary.quote.text}} className="f3 lh-copy measure-widest center pl3 pr3 pl6-l pr6-l pt4 pb4 medium" />
    <div className="blue f4 tc bold">{input.primary.author.text}</div>
  </div>
);

export default SliceQuote;

SliceQuote.propTypes = {
  input: PropTypes.object.isRequired
};
