import React from 'react';
import PropTypes from 'prop-types';
import '../css/slick.css';
import Slider from 'react-slick';
import Img from 'gatsby-image';

class BasicGallery extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    const {items} = this.props.input;
    const settings = {
      dots: true,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings} className="mt3 mb3-l center pl6-l pr6-l gallery">
        {items.map((slide, index) => (
          <div>
            <Img fluid={slide.image.localFile.childImageSharp.fluid} />
          </div>
        ))}
      </Slider>
    );
  }
}

export default BasicGallery;

BasicGallery.propTypes = {
  input: PropTypes.object.isRequired
};
