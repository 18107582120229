import React from 'react';
import PropTypes from 'prop-types';
import PrismicLink from '../components/PrismicLink';
const Faqs = ({input}) => (
  <div className="pl3 pr3 pl6-l pr6-l mw9 center">
    {input.items.map(faq => (
      <div className="flex-l mt3 mb3 pt5-l pb5-l pt4 pb4 bb w-100 justify-between">
        <div className="w-100 w-40-l f3 medium lh-copy">{faq.title.text}</div>
        <div className="w-100 w-40-l">
          <div dangerouslySetInnerHTML={{__html: faq.text.html}} className="f4 lh-copy faq" />
          {faq.link && (
            <PrismicLink to={faq.link.url} type={faq.link.link_type} className="link blue f4 medium">
              {faq.link_text.text}
            </PrismicLink>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default Faqs;

Faqs.propTypes = {
  input: PropTypes.object.isRequired
};
