import React from 'react';
import PropTypes from 'prop-types';

const Spacer = () => (
  <div className="db h2 h4-l"></div>
);
export default Spacer;

Spacer.propTypes = {
  input: PropTypes.object.isRequired
};
