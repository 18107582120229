import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PageBanner = ({bannerImage}) => (
  <div className="w-100 obj-fit-l overflow-hidden flex-l page-banner">
    <Img fluid={bannerImage.localFile.childImageSharp.fluid} className="w-100 h-auto" />
  </div>
);

export default PageBanner;

PageBanner.propTypes = {
  input: PropTypes.object.isRequired
};
