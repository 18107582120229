import React from 'react';
import PropTypes from 'prop-types';

const CenteredTextBlock = ({input}) => (
  <div className="mt5-l mb5-l mt4 pb3 pl4 pr4 relative">
    <h2 className="blue tc f2 f1-l">{input.primary.title.text}</h2>
    <div className="measure-widest center tc lh-copy pl3 pr3 pl4-l pr4-l f4-l f6" dangerouslySetInnerHTML={{__html: input.primary.text.html}} />
  </div>
);
export default CenteredTextBlock;

CenteredTextBlock.propTypes = {
  input: PropTypes.object.isRequired
};
