import React from 'react';
import PropTypes from 'prop-types';

const TwoColText = ({text, classNames}) => (
  <div className={`pt4 pb4-l pl3 pr3 relative ${classNames}`}>
    <div className="measure-widest center two-col lh-copy f4" dangerouslySetInnerHTML={{__html: text}} />
  </div>
);
export default TwoColText;

TwoColText.propTypes = {
  input: PropTypes.object.isRequired
};
