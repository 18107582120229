import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import ServiceSlider from '../components/ServiceSlider';

const ServiceSliderSlice = () => (
  <StaticQuery
    query={graphql`
      query {
        services: allPrismicService(sort: {fields: [data___service_weight], order: DESC}) {
          edges {
            node {
              uid
              data {
                service_name {
                  text
                }
                portal_text {
                  text
                }
                service_weight
                portal_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1800, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ServiceSlider input={data.services.edges} />}
  />
);
export default ServiceSliderSlice;
