import React from 'react';
import PropTypes from 'prop-types';
import MapContainer from '../components/MapContainer';

const ContactBranch = ({input}) => (
  <div className={`flex-l mt5 mb5 items-center map-${input.primary.map_position}`}>
    <div className="w-50-l w-100 overflow-hidden relative map-container">
      <MapContainer location={input.primary.location} />
    </div>
    <div className="w-50-l w-100 pl6-l pr6-l pr3 pl3 mt4 mt0-l branch-contact-details">
      <div className="blue f2 medium">{input.primary.location_name.text}</div>
      <div dangerouslySetInnerHTML={{__html: input.primary.contact_details.html}} className="mt4" />
    </div>
  </div>
);

export default ContactBranch;

ContactBranch.propTypes = {
  input: PropTypes.object.isRequired
};
