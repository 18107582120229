import React from 'react';
import PropTypes from 'prop-types';

const HeaderSpacer = ({input}) => <div className="header-spacer db" />;

export default HeaderSpacer;

HeaderSpacer.propTypes = {
  input: PropTypes.object.isRequired
};
