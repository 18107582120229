import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageBanner from '../components/PageBanner';
import SliceZone from '../components/SliceZone';

const ContactPage = ({data: {prismicContactPage}}) => {
  const {data} = prismicContactPage;

  return (
    <Layout>
      <SEO title="Welcome" keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']} />
      <div className="header-spacer-mobile" />
      <PageBanner bannerImage={data.banner_image} />
      <div className="db h3" />
      <h1 className="blue center mt3 mb3 mt5-l mb6-l f2 f1-l tc">{data.title.text}</h1>
      <SliceZone allSlices={data.body} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicContactPage {
      data {
        banner_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title {
          text
        }
        body {
          __typename
          ... on PrismicContactPageBodyBranch {
            slice_type
            id
            primary {
              location {
                latitude
                longitude
              }
              location_name {
                text
              }
              contact_details {
                html
              }
              map_position
            }
          }
        }
      }
    }
  }
`;

export default ContactPage;
